
.CalendarDay__highlighted_calendar {
    background: #E8EAF6;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__highlighted_calendar:active {
    background: #3f51b5;
    color: white;
}

.CalendarDay:hover,
.CalendarDay__highlighted_calendar:hover {
    background: #7986CB;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid #7986CB;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid #3f51b5;
}
